<template>
  <div class="About">
    <section class="about-section text-center" id="about">
      <b-container>
        <b-row>
          <b-col lg="8" class="mx-auto">
            <h2 class="text-white mb-4">About Me</h2>
            <p class="text-white-50">
              Hi I'm Roshan, a developer and network engineer.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <div class="Socials" id="socials">
    <div class="contact-section bg-black">
      <b-container>
        <h2 class="text-white text-center">Social Media</h2>
        <div class="social d-flex justify-content-center">
          <a class="mx-2" href="https://www.linkedin.com/in/roshansaganti/">
            <b-icon icon="linkedin" aria-hidden="true"></b-icon>
          </a>
          <a class="mx-2" href="https://github.com/roshansaganti">
            <b-icon icon="github" aria-hidden="true"></b-icon>
          </a>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

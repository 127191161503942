<template>
  <div class="Footer">
    <footer class="footer bg-black small text-center text-white-50">
      <div class="container">Copyright © Roshan Saganti. {{ year }}.</div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style></style>

<template>
  <div class="Projects">
    <section class="projects-section bg-light" id="projects">
      <b-container>
        <h2 class="mb-4 text-center">Projects</h2>
        <div v-for="project in projects" :key="project.id">
          <div v-if="project.status == 'active'">
            <b-row class="align-items-center no-gutters mb-4 mb-lg-5">
              <b-col xl="8" lg="7">
                <a :href="project.url" target="_blank" rel="noreferrer">
                  <b-card class="">
                    <b-card-img
                      v-if="project.image"
                      class="img-fluid mb-3 mb-lg-0"
                      :src="require('../assets/' + project.image)"
                    />
                  </b-card>
                </a>
              </b-col>
              <b-col xl="4" lg="5">
                <div class="featured-text text-justify">
                  <h4>
                    <a :href="project.url" target="_blank" rel="noreferrer">{{
                      project.name
                    }}</a>
                  </h4>
                  <p class="text-black-50 mb-0">
                    {{ project.description }}
                    <br /><br />
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import projects from "../assets/data/projects.json";

export default {
  name: "Projects",
  data() {
    return {
      projects: projects,
    };
  },
};
</script>

<style></style>

<template>
  <div class="Research Papers">
    <section class="projects-section bg-dark text-white" id="papers">
      <b-container>
        <h2 class="mb-4 text-center">Research Papers</h2>
        <div v-for="paper in papers" :key="paper.id">
          <div v-if="paper.status == 'active'">
            <b-row class="justify-content-center no-gutters">
              <b-col lg="6">
                <a :href="paper.url" target="_blank" rel="noreferrer">
                  <b-card border="dark" class="paper-card">
                    <b-img
                      class=""
                      :src="require('../assets/' + paper.image)"
                      fluid
                    />
                  </b-card>
                </a>
              </b-col>
              <b-col lg="6">
                <div
                  class="bg-black text-center h-100 project paper-card-right"
                >
                  <div class="d-flex h-100">
                    <div
                      class="project-text w-100 my-auto text-center text-lg-left"
                    >
                      <h4 class="text-white">
                        <a :href="paper.url" target="_blank" rel="noreferrer">
                          {{ paper.name }}
                        </a>
                      </h4>
                      <p class="mb-0 text-white-50">
                        {{ paper.description }}
                      </p>
                      <hr class="d-none d-lg-block mb-0 ml-0" />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import papers from "../assets/data/papers.json";

export default {
  name: "ResarchPapers",
  data() {
    return {
      papers: papers,
    };
  },
};
</script>

<style></style>

<template>
  <div class="Navigation" id="top">
    <b-navbar
      id="mainNav"
      toggleable="lg"
      fixed="top"
      v-scroll="handleScroll"
      :class="shrink"
    >
      <b-container>
        <b-navbar-brand href="#" v-scroll-to="'#top'">Roshan Saganti</b-navbar-brand>
        <b-navbar-toggle
          aria-controls="navbarResponsive"
          target="navbarResponsive"
        ></b-navbar-toggle>
        <b-collapse id="navbarResponsive" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-scroll-to="'#about'">About</b-nav-item>
            <b-nav-item v-scroll-to="'#projects'">Projects</b-nav-item>
            <b-nav-item v-scroll-to="'#papers'">Research Papers</b-nav-item>
            <!-- <b-nav-item v-scroll-to="'#contact'">Contact</b-nav-item> -->
            <b-nav-item v-scroll-to="'#socials'">Socials</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      shrink: "",
    };
  },
  methods: {
    handleScroll: function (evt, el) {
      // When view scrolls past 50 pixels
      if (window.scrollY > 50) {
        this.shrink = "navbar-shrink";
      }
      // When view comes back to the top
      if (window.scrollY < 50) {
        this.shrink = "";
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="Masthead">
    <!-- masthead class causing errors -->
    <header class="masthead">
      <b-container class="d-flex h-100 align-items-center">
        <div class="mx-auto text-center">
          <h1 class="mx-auto my-0 text-uppercase">My Portfolio</h1>
          <!-- <h2 class="text-white-50 mx-auto mt-2 mb-5"></h2> -->
          <a class="btn btn-primary" v-scroll-to="'#projects'">
            See My Work
          </a>
        </div>
      </b-container>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <div id="app">
    <Navigation />
    <Masthead />
    <About />
    <Projects />
    <ResearchPapers />
    <!-- <Contact /> -->
    <Socials />
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Masthead from "@/components/Masthead.vue";
import About from "@/components/About.vue";
import Projects from "@/components/Projects.vue";
import ResearchPapers from "@/components/ResearchPapers.vue";
// import Contact from "@/components/Contact.vue";
import Socials from "@/components/Socials.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    Masthead,
    About,
    Projects,
    ResearchPapers,
    // Contact,
    Socials,
    Footer,
  },
};
</script>

<style></style>
